import React from "react"
import { Icon, Text, VStack } from "@chakra-ui/react"

const EmptyStateIcon: React.FC = () => {
  return (
    <Icon viewBox="0 0 249 200" width="249" height="200" fill="none">
      <path
        d="M162.294 7.20216C146.226 10.6664 130.605 23.8032 96.351 16.8747C32.2516 3.91076 -19.4385 30.1699 8.00902 104.813C35.4565 179.457 88.9615 219.796 141.559 190.282C180.17 168.617 174.833 134.904 201.863 128.717C252.192 117.194 260.129 76.1414 233.733 40.325C207.337 4.50855 183.67 2.57116 162.294 7.20216Z"
        fill="#EAF4FE"
      />
      <path
        d="M108.868 94.7375C108.868 94.7375 107.637 94.8959 108.321 101.508C109.005 108.119 111.504 117.669 111.504 117.669L111.216 102.285C111.216 102.285 110.849 96.4084 108.868 94.7375Z"
        fill="#E9D6B8"
      />
      <path
        d="M110.518 112.513C110.763 115.66 109.43 117.115 109.077 118.397C108.821 119.282 108.511 120.15 108.148 120.997C109.329 120.212 109.286 119.189 109.639 118.649C109.992 118.109 109.805 118.267 109.97 119.369C110.064 120.01 109.128 121.198 108.616 122.041C108.379 122.395 108.229 122.799 108.177 123.222C108.819 122.62 109.398 121.954 109.905 121.234C110.608 120.199 111.19 119.088 111.641 117.921C111.801 116.491 111.733 115.045 111.44 113.636C111.101 111.929 110.345 110.244 110.518 112.513Z"
        fill="#E9D6B8"
      />
      <path
        d="M94.6584 65.4678C94.6584 66.8718 95.2162 68.2182 96.2089 69.211C97.2016 70.2037 98.5481 70.7614 99.952 70.7614L101.306 68.2479C102.71 68.2479 104.056 67.6901 105.049 66.6974C106.042 65.7047 106.6 64.3582 106.6 62.9543V60.9737C106.6 59.569 106.042 58.2219 105.05 57.228C104.057 56.2341 102.711 55.6748 101.306 55.6729H99.9809C98.5762 55.6748 97.2298 56.2341 96.2373 57.228C95.2447 58.2219 94.6872 59.569 94.6873 60.9737L94.6584 65.4678Z"
        fill="#282723"
      />
      <path
        d="M108.307 173.983L108.688 182.633L114.133 183.065L116.553 182.77L111.756 181.257L111.216 174.062L108.307 173.983Z"
        fill="#E9D6B8"
      />
      <path
        d="M109.106 183.742L110.294 183.901L110.503 183.569L114.99 183.656L117.151 183.087C117.151 183.087 117.237 182.705 117.036 182.59C116.079 182.291 115.09 182.11 114.09 182.05C114.09 182.05 114.4 182.489 114.155 182.676C113.91 182.863 110.907 182.763 110.165 182.676C108.883 182.46 108.472 182.078 108.436 182.186C108.371 182.395 109.106 183.742 109.106 183.742Z"
        fill="#161711"
      />
      <path
        d="M109.949 111.194L110.669 143.936L112.894 176.065L107.99 176.453C107.99 176.453 103.409 144.08 102.862 141.278C102.314 138.476 96.5094 116.013 99.2175 110.309C101.925 104.605 109.949 111.194 109.949 111.194Z"
        fill="#387BFF"
      />
      <path
        d="M90.7549 92.9729C88.0109 96.286 86.6208 109.898 86.6208 109.898C86.6208 109.898 87.5283 115.105 92.5699 97.6544C94.1039 92.3824 91.4247 92.1879 90.7549 92.9729Z"
        fill="#E9D6B8"
      />
      <path
        d="M87.7876 121.645L86.6425 120.384L86.0159 119.146L85.843 116.899C85.8824 115.787 86.0442 114.683 86.3256 113.607C86.6929 112.714 86.6137 109.156 86.7433 108.724C86.8729 108.292 89.12 107.752 89.12 107.752C89.12 107.752 88.1333 111.439 87.874 112.93C87.6148 114.421 87.9605 116.69 87.874 117.252C87.7834 117.87 87.6089 118.473 87.3555 119.045C87.3555 119.045 87.2907 117.453 87.3555 117.035C87.4203 116.618 86.8945 116.798 86.8945 116.798C86.8165 117.726 86.8359 118.66 86.9522 119.585C87.0912 119.961 87.2907 120.311 87.5427 120.622L87.7876 121.645Z"
        fill="#E9D6B8"
      />
      <path
        d="M105.375 85.3603L107.14 103.819V104.885L112.419 104.583C112.419 104.583 110.892 90.337 110.662 89.6528C110.255 87.0927 110.014 84.5089 109.941 81.9176C110.021 80.8445 109.79 75.4861 106.268 76.019C102.747 76.552 105.375 85.3603 105.375 85.3603Z"
        fill="#B0BFDE"
      />
      <path
        d="M93.9599 85.3603L92.1953 103.819V104.885L86.9161 104.583C89.3001 93.7796 89.797 91.619 89.905 89.6528C89.905 88.9325 90.0563 82.9907 89.9843 81.9176C89.8519 80.7425 90.0715 79.5546 90.6152 78.5045C91.1588 77.4543 92.0021 76.5893 93.038 76.019C96.0989 74.1825 93.9599 85.3603 93.9599 85.3603Z"
        fill="#B0BFDE"
      />
      <path
        d="M99.6568 172.989L99.5775 184.088L94.9825 182.482C94.9825 182.482 93.038 181.041 94.7161 181.257C97.1648 181.524 96.6679 180.494 96.7399 178.499C96.7365 176.555 96.6355 174.613 96.4374 172.68L99.6568 172.989Z"
        fill="#E9D6B8"
      />
      <path
        d="M94.3704 181.257C94.7062 181.565 95.0649 181.847 95.4435 182.1C96.2864 182.61 97.1854 183.021 98.1227 183.324C98.6142 183.415 99.1156 183.439 99.6136 183.396C99.6136 183.396 99.9665 184.549 99.1958 184.47C97.418 183.924 95.6999 183.2 94.0679 182.309C93.5277 181.949 93.4557 181.329 94.3704 181.257Z"
        fill="#161711"
      />
      <path
        d="M98.6341 111.807L99.2246 141.833L99.7576 173.673L95.8756 173.472C95.8756 173.472 91.9576 141.422 91.6335 138.584C91.3094 135.747 86.592 116.906 89.7538 111.396C92.9155 105.886 98.6341 111.807 98.6341 111.807Z"
        fill="#387BFF"
      />
      <path
        d="M99.3975 74.593C99.3975 74.593 105.426 74.9963 106.852 75.6157C108.278 76.2351 107.975 83.2644 107.053 88.4788C106.132 93.6932 106.549 99.5054 107.874 102.768C109.2 106.031 110.222 112.268 110.222 112.268L89.0624 111.958C89.0624 111.958 93.859 98.994 93.859 93.9885C93.859 88.983 92.5338 77.5531 92.6347 76.4296C92.7355 75.306 98.0723 74.593 99.3975 74.593Z"
        fill="#D1DBEA"
      />
      <path
        d="M95.3139 76.9913C95.2838 78.4518 95.6894 79.888 96.479 81.117C97.2685 82.346 98.4062 83.312 99.7469 83.8919C101.088 84.4717 102.571 84.6391 104.007 84.3726C105.443 84.1062 106.768 83.4181 107.811 82.396C108.855 81.3738 109.57 80.0641 109.866 78.6337C110.163 77.2033 110.026 75.7171 109.474 74.3646C108.922 73.0121 107.98 71.8546 106.768 71.0397C105.556 70.2248 104.128 69.7894 102.667 69.7891C101.711 69.7787 100.763 69.9567 99.8758 70.3131C98.9888 70.6695 98.1807 71.1972 97.4978 71.8661C96.8149 72.535 96.2705 73.3319 95.8957 74.2113C95.521 75.0908 95.3233 76.0354 95.3139 76.9913Z"
        fill="#282723"
      />
      <path
        d="M89.6961 71.6833C89.6661 73.1438 90.0717 74.5801 90.8612 75.8091C91.6507 77.0381 92.7884 78.0041 94.1291 78.5839C95.4699 79.1637 96.9529 79.3311 98.3892 79.0647C99.8254 78.7982 101.15 78.1101 102.193 77.088C103.237 76.0659 103.952 74.7561 104.249 73.3257C104.545 71.8953 104.408 70.4091 103.857 69.0566C103.305 67.7041 102.363 66.5466 101.15 65.7317C99.9379 64.9168 98.5103 64.4815 97.0496 64.4811C96.0937 64.4707 95.1451 64.6487 94.2581 65.0051C93.3711 65.3615 92.563 65.8892 91.8801 66.5581C91.1971 67.227 90.6527 68.0239 90.278 68.9034C89.9033 69.7828 89.7055 70.7274 89.6961 71.6833Z"
        fill="#282723"
      />
      <path
        d="M91.6911 80.1819C91.6611 81.6423 92.0667 83.0786 92.8562 84.3076C93.6457 85.5366 94.7834 86.5026 96.1241 87.0825C97.4649 87.6623 98.9479 87.8297 100.384 87.5632C101.82 87.2968 103.145 86.6087 104.188 85.5866C105.232 84.5644 105.947 83.2547 106.244 81.8243C106.54 80.3939 106.403 78.9077 105.852 77.5552C105.3 76.2027 104.358 75.0452 103.145 74.2303C101.933 73.4154 100.505 72.98 99.0446 72.9797C97.1146 72.9605 95.256 73.7083 93.8772 75.0588C92.4983 76.4093 91.712 78.252 91.6911 80.1819Z"
        fill="#282723"
      />
      <path
        d="M95.1626 69.4579C95.1326 70.9186 95.5384 72.3552 96.3282 73.5843C97.118 74.8135 98.2561 75.7795 99.5973 76.3591C100.938 76.9387 102.422 77.1057 103.858 76.8387C105.295 76.5717 106.619 75.8828 107.662 74.86C108.706 73.8371 109.421 72.5267 109.716 71.0958C110.011 69.6649 109.874 68.1785 109.321 66.8261C108.768 65.4737 107.825 64.3167 106.611 63.5027C105.398 62.6887 103.97 62.2545 102.509 62.2557C101.554 62.2462 100.606 62.4249 99.7196 62.7817C98.8334 63.1385 98.0262 63.6664 97.344 64.3352C96.6619 65.004 96.1181 65.8006 95.7438 66.6796C95.3695 67.5585 95.172 68.5026 95.1626 69.4579Z"
        fill="#282723"
      />
      <path
        d="M47.8082 173.335C48.0531 173.047 47.8731 172.269 47.8731 172.269C47.8731 172.269 43.6094 171.794 42.241 172.111C41.706 172.291 41.1937 172.533 40.7141 172.831C40.6205 172.896 40.4476 173.22 40.354 173.27C40.2604 173.32 40.1019 173.205 39.9507 173.27C39.7216 173.403 39.5025 173.552 39.2953 173.717C38.9208 175.676 39.4249 176.461 39.4249 176.461C39.4249 176.461 42.9252 177.555 43.7462 176.828C45.77 175.013 47.2897 173.983 47.8082 173.335Z"
        fill="#161711"
      />
      <path
        d="M61.3052 174.948L61.9102 178.132H65.1511C65.1511 178.132 65.3312 177.678 65.6049 177.678C65.8786 177.678 66.1378 178.132 66.4547 178.132H74.694C74.694 178.132 74.8381 177.462 74.694 177.231C74.3862 177.035 74.0479 176.892 73.6929 176.806C72.022 176.338 67.211 174.869 66.3971 173.263C65.3528 171.318 61.3052 174.948 61.3052 174.948Z"
        fill="#161711"
      />
      <path
        d="M63.8547 67.8373L64.6326 63.9842C64.6326 63.9842 67.3622 64.2002 67.8952 62.7238C68.9683 59.7709 68.068 55.0535 67.4198 53.7283C65.576 49.9687 64.7982 43.3643 56.17 48.6147C52.893 50.6097 57.9633 60.2751 58.3307 62.3709C58.4908 63.9086 58.2423 65.4613 57.6104 66.8722L59.6343 68.4207L63.8547 67.8373Z"
        fill="#E9D6B8"
      />
      <path
        d="M48.5429 104.28L48.262 116.423C48.262 116.423 43.6886 111.382 43.2205 105.62C42.7523 99.8582 48.262 103.834 48.5429 104.28Z"
        fill="#DBC9AD"
      />
      <path
        d="M53.6852 129.308C53.6852 129.308 54.9528 129.855 51.2293 147.508C48.5501 160.22 46.2598 169.518 45.1867 173.724C44.7689 175.359 44.3008 175.063 44.3008 175.063L40.7645 175.46L39.072 174.019L44.1135 145.21L53.6852 129.308Z"
        fill="#163F59"
      />
      <path
        d="M76.8763 96.3724L69.6165 110.287C69.6165 110.287 83.459 100.276 82.5804 94.7591C81.7017 89.2422 76.8763 96.3724 76.8763 96.3724Z"
        fill="#E9D6B8"
      />
      <path
        d="M48.4637 71.5897C48.4637 71.5897 45.5252 73.3903 43.4221 88.0035C41.3191 102.617 42.6515 102.055 42.6515 102.055L43.0332 105.382L48.5357 105.973L51.5102 93.3619L48.4637 71.5897Z"
        fill="#B0BFDE"
      />
      <path
        d="M61.9749 109.531C61.9749 109.531 53.8581 143.143 47.4913 158.21C43.7534 167.033 43.7246 150.093 43.7246 150.093L49.5296 101.292L66.4619 100.47C66.4619 100.47 64.5749 101.061 61.9749 109.531Z"
        fill="#163F59"
      />
      <path
        d="M56.0692 100.564C56.0692 100.564 62.1982 144.08 62.8392 146.046C64.9999 152.686 68.3849 143.979 69.1627 141.761C69.9406 139.542 71.2153 123.157 70.9561 111.194L70.6968 99.2388L56.0692 100.564Z"
        fill="#163F59"
      />
      <path
        d="M62.2271 135.163C62.2271 135.163 61.298 140.349 60.5346 146.982C59.9656 151.851 59.9512 165.758 60.8442 175.416C60.9019 176.007 67.0381 174.206 67.0381 174.206C67.0381 174.206 69.2852 147.169 69.7966 141.12C70.3079 135.07 66.0802 130.835 62.2271 135.163Z"
        fill="#163F59"
      />
      <path
        d="M48.2836 71.6257L55.529 68.3343L57.524 66.4401L65.2232 66.2601L66.5772 68.4783L72.5406 71.0639C72.5406 71.0639 73.981 73.383 72.7422 81.9752C71.3018 91.5037 71.0137 105.894 71.0137 105.894L48.4421 106.988C48.4421 106.988 50.4011 76.2423 48.2836 71.6257Z"
        fill="#D1DBEA"
      />
      <path
        d="M70.6248 71.0855C70.6248 71.0855 71.9716 69.5875 75.2414 73.6567C80.8158 80.5852 83.5167 89.1918 84.0928 90.8555C84.669 92.5192 85.4108 94.8744 83.1349 97.64C81.3848 99.7574 80.823 100.47 80.823 100.47L75.7815 97.3519L76.5666 95.8539L77.1283 95.1336C77.1283 95.1336 74.4347 89.2206 71.5394 83.855C68.6442 78.4894 70.6248 71.0855 70.6248 71.0855Z"
        fill="#D1DBEA"
      />
      <path
        d="M65.0143 54.8374C65.0143 54.8374 64.042 54.2324 63.8907 54.9814C63.7395 55.7305 64.2652 57.387 64.719 57.531C65.1727 57.6751 65.3168 59.6917 65.3168 59.6917L64.114 61.9387H58.0426C58.0426 61.9387 56.17 61.1105 56.0187 59.7781C55.8675 58.4457 54.3694 48.9028 54.3694 48.9028L58.6403 46.6557C59.8782 46.0391 61.1587 45.5119 62.4719 45.0784C64.8846 44.3582 65.3528 45.6762 65.3528 46.3532C65.3528 47.0302 66.0298 47.0734 67.0021 47.0734C67.9744 47.0734 69.1627 47.7937 68.6514 48.2762C68.4353 48.4635 69.3716 49.054 69.0187 49.7671C68.4713 50.797 66.6276 51.1211 66.4763 52.7776C66.3755 53.5626 66.0658 56.033 65.0143 54.8374Z"
        fill="#194866"
      />
      <path
        d="M126.946 71.7193L128.105 70.5814C157.353 69.4218 168.733 61.7587 168.733 49.4574V48.9748C168.733 38.5317 160.811 32.4962 147.378 32.4962C134.378 32.4962 123.237 37.8331 112.563 48.5067L91.223 25.0636C104.669 10.2127 122.769 0 148.07 0C181.495 0 204.247 17.1772 204.247 48.0458V48.5067C204.247 77.9852 183.821 90.0561 158.52 95.1624L155.971 109.322H132.275L126.946 71.7193ZM124.627 127.421H162.229V164.563H124.627V127.421Z"
        fill="#0063CB"
      />
    </Icon>
  )
}

interface IEmptyState {
  description?: string
}
const EmptyState: React.FC<IEmptyState> = ({ description }) => {
  return (
    <VStack>
      <EmptyStateIcon />
      {description && <Text>{description}</Text>}
    </VStack>
  )
}

export default EmptyState
