import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  fonts: {
    heading: `'JetBrains Mono', sans-serif`,
    body: `'Inter', sans-serif`,
  },
})

export default theme
